<template>
  <div>
    <div class="paysuccess_wrap">
      <div class="paysuccess_con">
        <div class="content">
          <i><img style="width: 65px" src="../../assets/images/pay/支付成功-copy.png" alt=""></i>
          <div>
            <p>您的订单已支付成功！</p>
            <p>将在人工审核信息无误后上缴{{isflag == 1 ? "商标" : "专利"}}局</p>
            <p>您可以在年费订单中查看状态并查看下载收据</p>
          </div>
        </div>
        <div class="line"></div>
        <p class="nav">
          <span style="color: #727272;"  @click="serverEvent(false)">
            <i class="el-icon-arrow-left"></i>
            返回{{isflag == 1 ? "商标" : "专利"}}列表
          </span>
          <span style="color: #2E54D9;" class="active" @click="orderEvent(true)">查看订单</span>
        </p>
      </div>
    </div>
    <footers></footers>
  </div>
</template>
<script>
import footers from '@/components/common/xfooter'
export default {
  name: "paySuccess",
  data () {
    return {
      isflag: 1,
      td: '',
      timer: null
    }
  },
  components: { footers },
  mounted() {
    this.isflag = localStorage.getItem('type')
    this.td = this.$route.query.td
  },
  destroyed () {
    clearInterval(this.timer);
  },
  methods: {
    serverEvent (item) {
        this.$router.push({
          path: this.isflag == 1 ? "/hostingPlatforms/trademark" : "/hostingPlatforms/annualFee"
        })
    },
    orderEvent () {
      this.$router.push({
        path: this.isflag == 1 ? "/hostingPlatforms/myRenewal" : "/hostingPlatforms/myAnnualFee"
      })
    },
  }
}
</script>
<style lang="less" scoped>
.paysuccess_wrap {
  min-height: calc(100vh - 85px);
  padding: 89px 100px 9px;
  box-sizing: border-box;
  background: #f2f2f2;
  .paysuccess_con {
    width: 100%;
    min-height: calc(100vh - 183px);
    background: #fff;
    padding: 160px 104px 80px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0px 3px 30px 0px rgba(152, 160, 164, 0.18);
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-size: 28px;
      color: #243c5e;
      margin-bottom: 35px;
      div{
        margin-left: 20px;
        p{
          text-align: center;
          &:nth-of-type(1){
            margin-top: 32px;
            font-size: 32px;
            font-weight: 500;
            color: #304664;
            line-height: 45px;
            letter-spacing: 1px;
          }
          &:nth-of-type(2){
            font-size: 20px;
            font-weight: 400;
            color: #849DCD;
            margin-top: 28px;
            line-height: 28px;
          }
          &:nth-of-type(3){
            margin-top: 10px;
            font-size: 20px;
            font-weight: 400;
            color: #878787;
            line-height: 28px;
          }
        };
      }
    }
    .line{
      height: 3px;
      background: #E1E4FA;
    }
    .nav{
      display: flex;
      span{
        cursor: pointer;
      }
      margin-top: 38px;
      justify-content: space-between;
    }
  }
}
</style>
